@keyframes fadeInUp-Blurb {
    from {
       opacity: 0;
       transform: translateY(50px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.fadeInUp-Blurb {
    -webkit-animation-name: fadeInUp-Blurb;
    animation-name: fadeInUp-Blurb;
    animation-timing-function: cubic-bezier(0.15, 0, 0.15, 1);
    -webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.15, 1);
}

@keyframes fadeInUp-Card {
    from {
       opacity: 0;
       transform: translateY(15px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.fadeInUp-Card {
    -webkit-animation-name: fadeInUp-Card;
    animation-name: fadeInUp-Card;
    animation-timing-function: cubic-bezier(0.15, 0, 0.15, 1);
    -webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.15, 1);
}

@keyframes fadeIn {
    from {
       opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    animation-timing-function: cubic-bezier(0.15, 0, 0.15, 1);
    -webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.15, 1);
}

@keyframes fadeInUp-NotFound {
    from {
        opacity: 0;
        transform: translateY(30px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.fadeInUp-NotFound {
    -webkit-animation-name: fadeInUp-NotFound;
    animation-name: fadeInUp-NotFound;
    animation-timing-function: cubic-bezier(0.15, 0, 0.15, 1);
    -webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.15, 1);
}
