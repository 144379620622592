li {
    list-style-type: none;
}
#dropdown {
    outline: none;
    box-shadow: none;
}
.CrossfadeImage {
    height: 100%;
    position: fixed !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -10000;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
}
#name::placeholder {
    color: white;
    opacity: 1;
}
#email::placeholder {
    color: white;
    opacity: 1;
}
#phone::placeholder {
    color: white;
    opacity: 1;
}
#message::placeholder {
    color: white;
    opacity: 1;
}
#ddMenu:focus {
    outline: none;
}
#ddSearch:focus {
    outline: none;
    box-shadow: none;
}
.react-slideshow-fade-images-wrap, .react-slideshow-fade-images-wrap > div, .react-slideshow-fade-wrapper, .react-slideshow-container, .slideshowWrapper {
    height: 100% !important;
}
.slick-slider{
    width: 100%;
}
.sliderWrapper {
    padding: 20px;
    overflow: hidden;
}
.sliderWrapper img {
    height: 100% !important;
    width: 100% !important;
    object-fit: cover;
}

.vertical-timeline::before{
    background-color: #B3ACA6 !important;
    width: 2px !important;
    opacity: 50% !important;
}

.vertical-timeline-element-content {
    box-shadow: none !important;
}
