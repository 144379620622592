body {
  font-family: acumin-pro, sans-serif !important;
}
#searchInput:focus {
  outline: none;
  box-shadow: none;
}
/* Begin Article styles */
p.dropCap:first-letter {
  position: relative;
  float: left;
  display: block;
  font-size: 150px;
  line-height: 125px;
  font-weight: 300;
  padding-top: 3px;
  padding-right: 25px;
  padding-left: 0;
  color: #E86956;
  text-transform: uppercase;
  font-family: 'capitolina';
}
 p{
  font-size: 16px !important;
}
h1, h2, h3, h4, h5, h6 {
  line-height: inherit !important;
}
h1 {
  color: #852D3D;
  font-family: 'capitolina';
  font-weight: 700 !important;
}
h2 {
  color: #862C3C;
  font-weight: 300 !important;
}
h3 {
  color: #7D7773;
}
h4 {
  color: #852D3D;
  text-transform: uppercase;
  letter-spacing: 1.25px;
  font-weight: 700 !important;
}
h5 {
  color: #7D7773;
  text-transform: uppercase;
  letter-spacing: 1.25px;
  font-weight: 600 !important;
}
h6 {
  color: #7D7773;
  font-weight: 700 !important;
}
blockquote {
  color: #862C3C;
  display: inline-block;
  padding-left: 50px;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
  font-weight: 500;
  border-left: solid #EDE8E4 6px;
}

.articleAnchor, .articleAnchor:link {
  color: #862C3C !important;
  text-decoration: underline;
}
.articleUl {
  padding-inline-start: 34px;
}
.articleUl li {
  list-style: none;
  position: relative;
}
.articleUl li::before {
  color: #E86956;
  content: "\2022";
  display: inline-block;
  font-size: 40px;
  left: -34px;
  position: absolute;
  top: -29px;
}
.articleOl {
  counter-reset: li;
  padding-inline-start: 0;
}
.articleOl li::before {
  content: counter(li) '.';
  counter-increment: li;
  color: #E86956;
  font-weight: 800;
  display: inline-block;
  width: 1em;
  margin-right: 1em;
  letter-spacing: 1px;
}

.articleOl li {
  display: flex;
}

/* End Article styles */
/* Begin Timeline styles */
.vertical-timeline-element{
  height: 320px !important;
  padding-top: 50px !important;
}
.vertical-timeline-element-icon {
  /*top: 106px !important;*/
}
.vertical-timeline::before{
  background-color: #B3ACA6 !important;
  width: 2px !important;
  opacity: 50% !important;
}
.vertical-timeline-element-content {
  box-shadow: none !important;
}
.vertical-timeline--two-columns .vertical-timeline-element-content-arrow {
  display: none !important;
}
.vertical-timeline-element-content {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.vertical-timeline--two-columns .vertical-timeline-element-content {
  width: 48% !important;
}
.vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
  top: -30px !important;
}
@media (max-width: 991px) {
  .vertical-timeline-element{
    height: auto !important;
    padding-top: 0 !important;
    margin: 0 !important;
  }
  .vertical-timeline--two-columns .vertical-timeline-element-content {
    width: unset !important;
  }
  .vertical-timeline-element-icon {
    /*top: 50px !important;*/
  }
}
/* Begin default Timeline styles */
.vertical-timeline * {
  box-sizing: border-box;
}
.vertical-timeline {
  width: 95%;
  max-width: 1170px;
  margin: 0 auto;
  position: relative;
  padding: 2em 0;
}
.vertical-timeline::after {
  content: "";
  display: table;
  clear: both;
}
.vertical-timeline::before {
  content: "";
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  background: #fff;
}
@media only screen and (min-width: 992px) {
  .vertical-timeline.vertical-timeline--two-columns {
    width: 90%;
  }
  .vertical-timeline.vertical-timeline--two-columns:before {
    left: 50%;
    margin-left: -2px;
  }
}
.vertical-timeline-element {
  position: relative;
  margin: 2em 0;
  height: auto !important;
}
.vertical-timeline-element > div {
  min-height: 1px;
}
.vertical-timeline-element:after {
  content: "";
  display: table;
  clear: both;
}
.vertical-timeline-element:first-child {
  margin-top: 0;
}
.vertical-timeline-element:last-child {
  margin-bottom: 0;
}
@media only screen and (min-width: 992px) {
  .vertical-timeline-element {
    margin: 4em 0;
  }
  .vertical-timeline-element:first-child {
    margin-top: 0;
  }
  .vertical-timeline-element:last-child {
    margin-bottom: 0;
  }
}
.vertical-timeline-element-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
}
.vertical-timeline-element-icon svg {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -12px;
}
@media only screen and (min-width: 992px) {
  .vertical-timeline--two-columns .vertical-timeline-element-icon {
    width: 60px;
    height: 60px;
    left: 50%;
    margin-left: -30px;
  }
}
.vertical-timeline-element-icon {
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
}
.vertical-timeline--animate .vertical-timeline-element-icon.is-hidden {
  visibility: hidden;
}
.vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
  visibility: visible;
  -webkit-animation: cd-bounce-1 0.6s;
  -moz-animation: cd-bounce-1 0.6s;
  animation: cd-bounce-1 0.6s;
}
@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -moz-transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -moz-transform: scale(1.2);
  }
  100% {
    -moz-transform: scale(1);
  }
}
@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.vertical-timeline-element-content {
  position: relative;
  margin-left: 60px;
  background: #fff;
  border-radius: 0.25em;
  padding: 1em;
  box-shadow: 0 3px 0 #ddd;
}
.vertical-timeline-element--no-children .vertical-timeline-element-content {
  background: 0 0;
  box-shadow: none;
}
.vertical-timeline-element-content:after {
  content: "";
  display: table;
  clear: both;
}
.vertical-timeline-element-content h2 {
  color: #303e49;
}
.vertical-timeline-element-content .vertical-timeline-element-date,
.vertical-timeline-element-content p {
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 500;
}
.vertical-timeline-element-content .vertical-timeline-element-date {
  display: inline-block;
}
.vertical-timeline-element-content p {
  margin: 1em 0 0;
  line-height: 1.6;
}
.vertical-timeline-element-title {
  margin: 0;
}
.vertical-timeline-element-subtitle {
  margin: 0;
}
.vertical-timeline-element-content .vertical-timeline-element-date {
  float: left;
  padding: 0.8em 0;
  opacity: 0.7;
}
.vertical-timeline-element-content-arrow {
  content: "";
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid #fff;
}
.vertical-timeline-element--no-children .vertical-timeline-element-content-arrow {
  display: none;
}
@media only screen and (min-width: 768px) {
  .vertical-timeline-element-content h2 {
    font-size: 20px;
    font-size: 1.25rem;
  }
  .vertical-timeline-element-content p {
    font-size: 16px;
    font-size: 1rem;
  }
  .vertical-timeline-element-content .vertical-timeline-element-date {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 992px) {
  .vertical-timeline--two-columns .vertical-timeline-element-content {
    margin-left: 0;
    padding: 1.5em;
    width: 44%;
  }
  .vertical-timeline--two-columns .vertical-timeline-element-content-arrow {
    top: 24px;
    left: 100%;
    transform: rotate(180deg);
  }
  .vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
    position: absolute;
    width: 100%;
    left: 118%;
    top: 6px;
    font-size: 16px;
    font-size: 1rem;
  }
  .vertical-timeline--two-columns .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content,
  .vertical-timeline--two-columns .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content {
    float: right;
  }
  .vertical-timeline--two-columns .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content-arrow,
  .vertical-timeline--two-columns .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content-arrow {
    top: 24px;
    left: auto;
    right: 100%;
    transform: rotate(0);
  }
  .vertical-timeline--two-columns .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content .vertical-timeline-element-date,
  .vertical-timeline--two-columns .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content .vertical-timeline-element-date {
    left: auto;
    right: 118%;
    text-align: right;
  }
}
.vertical-timeline--animate .vertical-timeline-element-content.is-hidden {
  visibility: hidden;
}
.vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
  visibility: visible;
  -webkit-animation: cd-bounce-2 0.6s;
  -moz-animation: cd-bounce-2 0.6s;
  animation: cd-bounce-2 0.6s;
}
@media only screen and (min-width: 992px) {
  .vertical-timeline--two-columns.vertical-timeline--animate .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content.bounce-in,
  .vertical-timeline--two-columns.vertical-timeline--animate .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content.bounce-in {
    -webkit-animation: cd-bounce-2-inverse 0.6s;
    -moz-animation: cd-bounce-2-inverse 0.6s;
    animation: cd-bounce-2-inverse 0.6s;
  }
}
@media only screen and (max-width: 1169px) {
  .vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-2-inverse 0.6s;
    -moz-animation: cd-bounce-2-inverse 0.6s;
    animation: cd-bounce-2-inverse 0.6s;
  }
}
@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateX(20px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    -moz-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    -o-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -moz-transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateX(-20px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    -ms-transform: translateX(100px);
    -o-transform: translateX(100px);
    transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
/* End default Timeline styles */
/* End Timeline styles */

/* Begin Image Slider styles */
.sliderWrapper, .slick-slide.slick-slide-active {
  height: 325px !important;
  width: 325px !important;
  outline-width: 0;
}
.slick-slide.slick-active div {
  display: flex !important;
  justify-content: flex-start;
}
.slick-slide.slick-active.slick-current div {
  display: flex !important;
  justify-content: flex-end;
}
.slick-dots {
  position: static !important;
}
.slick-arrow.slick-next {
  border-style: solid;
  border-color: rgb(125, 119, 115);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 13px;
  margin-top: -20px;
  right: -40px;
  transition: border-color 0.25s;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.slick-arrow.slick-prev {
  border-style: solid;
  border-color: rgb(125, 119, 115);
  transition: border-color 0.25s;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 13px;
  margin-top: -20px;
  left: -40px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
.slick-arrow.slick-arrow.slick-next::before {
  content: "";
}
.slick-arrow.slick-arrow.slick-prev::before {
  content: "";
}
.slick-arrow.slick-next:hover {
  border-color: rgb(133, 45, 61);
}
.slick-arrow.slick-prev:hover {
  border-color: rgb(133, 45, 61);
}
.slick-slider {
  display: flex !important;
}
@media (max-width: 500px) {
  .slick-arrow.slick-prev {
    left: -25px;
  }
  .slick-arrow.slick-next {
    right: -25px;
  }
}
@media (max-width: 1290px) {
  .slick-slider{
    width: 700px !important;
  }
}
@media (max-width: 950px) {
  .slick-slide.slick-active.slick-current div {
    display: flex !important;
    justify-content: center;
  }
  .slick-slider {
    width: 400px !important;
    display: unset !important;
  }
  .sliderWrapper, .slick-slide.slick-slide-active {
    height: 400px !important;
    width: 400px !important;
  }
}

@media (max-width: 600px) {
  .slick-slider {
    width: 300px !important;
    height: 300px !important;
  }
  .sliderWrapper, .slick-slide.slick-slide-active {
    height: 300px !important;
    width: 300px !important;
  }
}
@media (max-width: 370px) {
  .slick-slider {
    width: 250px !important;
    height: 250px !important;
  }
  .sliderWrapper, .slick-slide.slick-slide-active {
    height: 250px !important;
    width: 250px !important;
  }
}
/* End Image Slider styles */

/* Begin Number Spinner Input styles */
.react-numeric-input {
  margin-right: 10px;
  margin-left: 15px;
}
.react-numeric-input input {
  width: 60px !important;
}
/* End Number Spinner Input styles */
#tileWrapper::after {
  content: "";
  flex: auto;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.splash-screen {
  background-color: rgb(2, 30, 39);
  position:fixed;
  top: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
}

.loading-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 20vh;
}
